@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');

// themes - our custom or/and out of the box themes
@import 'themes';

// framework component themes (styles tied to theme variables)
@import '@nebular/theme/styles/globals';
@import '@nebular/auth/styles/globals';

@import 'bootstrap/scss/functions';
@import 'bootstrap/scss/variables';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/grid';
@import "../../../../node_modules/angular-calendar/css/angular-calendar.css";

// loading progress bar theme
@import './pace.theme';

@import './layout';
@import './overrides';

// install the framework and custom global styles
@include nb-install() {

  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  @include ngx-layout();
  // loading progress bar
  @include ngx-pace-theme();

  @include nb-overrides();
};

input, textarea, nb-select {
  border: 1px solid #e2e2e2 !important;
}

nb-select {
  border-radius: 5px;
  overflow: hidden;
}

// button {
//   color: #000000 !important;
//   background-color: #FFEF00 !important;
// }

.modal-body {
  background-color: #151515 !important;
}

::ng-deep nb-card {
  background-color: #151515 !important;
}

.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-bar {
  background-color: #FFEF00;
}
.mat-slide-toggle.mat-checked:not(.mat-disabled) .mat-slide-toggle-thumb {
  background-color: #FFEF00;
}

.scroll-modal {
  height: 100%;
  overflow-y: scroll;
}

.scroll-modal::-webkit-scrollbar {
  display: none;
}

input[type='date']::-webkit-calendar-picker-indicator {
  filter: invert(1);
}